



















import { Component, Vue } from 'vue-property-decorator';

@Component({
  name: 'Error',
})
export default class Registration extends Vue {
    private readonly url: boolean;

    constructor() {
        super();
        this.url = this.$route.path.includes("edit");
    }
}
